'use strict'

angular
.module 'mundoAdmin.tasks'
# .config ($stateProvider) ->
#   $stateProvider
#   .state 'tasks',
#     url: '/tasks'
#     parent: 'admin'
#     views:
#       '@admin':
#         templateUrl: 'mundo-admin/tasks/views/tasks.tpl.html'
#         controller: 'TabsCtrl'
#         controllerAs: 'tabsCtrl'
#     deepStateRedirect: {default: {state: 'tasks.tasks'}},

#   .state 'tasks.tasks',
#     url: '/tasks'
#     data:
#       'selectedTab': 0
#     views:
#       'tasks@tasks':
#         templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#         controller: 'DataTableCtrl'
#         controllerAs: 'listCtrl'
#         resolve:
#           entityManager: (TasksManager) ->
#             TasksManager
#           pageTitle: ->
#             'app.admin.pageTitles.tasks.tasks'
#           pageDescription: ->
#             'app.admin.descriptions.tasks.tasks'
#           deleteBoxTitle: ->
#             'app.admin.actions.tasks.deleteTask'
#           deleteBoxBody: ->
#             'app.admin.actions.tasks.deleteTask'
#           AddBoxTitle: ->
#             'app.admin.actions.tasks.addTask'
#           editBoxTitle: ->
#             'app.admin.actions.tasks.editTask'
#           loadFilterParams: ->
#             {
#               'filter[closedAt]': 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()
#             }
#   .state 'tasks.types',
#     url: '/types'
#     data:
#       'selectedTab': 1
#     views:
#       'types@tasks':
#         templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#         controller: 'DataTableCtrl'
#         controllerAs: 'listCtrl'
#         resolve:
#           entityManager: (TaskTypeManager) ->
#             TaskTypeManager
#           pageTitle: ->
#             'app.admin.pageTitles.tasks.taskTypes'
#           pageDescription: ->
#             'app.admin.descriptions.tasks.taskTypes'
#           deleteBoxTitle: ->
#             'app.admin.actions.tasks.deleteTaskType'
#           deleteBoxBody: ->
#             'app.admin.actions.tasks.deleteTaskType'
#           AddBoxTitle: ->
#             'app.admin.actions.tasks.addTaskType'
#           editBoxTitle: ->
#             'app.admin.actions.tasks.editTaskType'
#           loadFilterParams: ->
#             {}
